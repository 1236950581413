
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;600&display=swap');

html, body {
  height: 100% !important;
}

$theme-colors: (
  "primary": rgb(0, 176, 0),
  "secondary": #4D4D4D,
  "danger": #ff4136,
  "warning": #f2c822,
  "info": #000000,
);

body {
  background-color: #efefef !important;
  margin: 0;
  font-family: 'Barlow', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-family: 'Barlow', sans-serif;
}

strong {
  font-weight: 600 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
}
h1, h2, h3, h4, h5, .card-title {
  color: #227b7e !important;
  font-family: 'Barlow', sans-serif !important;
  font-weight: 600 !important;
}

h1 {
  font-size: 1.5rem !important;
}

h2 {
  color: #4D4D4D !important;
  font-size: 1.25rem !important;
}

.btn-primary, .btn-danger {
  color: #FFF !important;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.amplify-tabs {
  display: none;
}

.page-link {
  color: rgb(0, 176, 0) !important;
}


.active > .page-link {
  background-color: rgb(0, 176, 0) !important;
  border-color:  rgb(0, 176, 0) !important;
  color: #FFF !important;
}

.even-toggle.react-toggle--checked .react-toggle-track {
  background-color: #4D4D4D;
}

.even-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #4D4D4D;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-toggle/style.css';
@import '~react-phone-number-input/style.css';
// @import "~react-datepicker/dist/react-datepicker.css";
// @import "~@aws-amplify/ui-react/styles.css";

.PhoneInputInput {
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da
}

.editor-fancy-blockquote {
  color: #999;
  background: #fff;
  font-family: 'serif';
  font-style: italic;
  border-left: 2px solid #999;
  padding-left: 10px;
}

.editor-left-align {
  text-align: left;
}

.editor-right-align {
  text-align: right;
}

.editor-center-align {
  text-align: center;
}

.editor-justify-align {
  text-align: justify;
}
